exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kwalifikowani-dostawcy-wegla-tsx": () => import("./../../../src/pages/kwalifikowani-dostawcy-wegla.tsx" /* webpackChunkName: "component---src-pages-kwalifikowani-dostawcy-wegla-tsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-wspolne-transporty-tsx": () => import("./../../../src/pages/wspolne-transporty.tsx" /* webpackChunkName: "component---src-pages-wspolne-transporty-tsx" */),
  "component---src-templates-history-item-tsx": () => import("./../../../src/templates/HistoryItem.tsx" /* webpackChunkName: "component---src-templates-history-item-tsx" */),
  "component---src-templates-history-legacy-item-index-tsx": () => import("./../../../src/templates/HistoryLegacyItem/index.tsx" /* webpackChunkName: "component---src-templates-history-legacy-item-index-tsx" */)
}

